import React, { useEffect } from "react";
import Search from "../../components-pl/home/search";
import { isEmpty } from "lodash";
import Layout from "../../components-pl/layout";
// import Carousel from "../../components-pl/home/carousel"
// import Hero from "../../images/hero1.jpg"
// import HeroMob from "../../images/hero-mob.jpg"

import Link from "gatsby-link";
// import ReactStars from "react-rating-stars-component"
import SEO from "../../components-pl/seo";
// import Revs from "../../components-pl/reviews";
import SliderIn from "../../components-pl/slider2.js";
// import PricingTable from "../../components-pl/pricing-table"
import SliderPhoto from "../../components/slider";
import BlogModule from "../../components-pl/post-module";
import HowWorks from "../../components-pl/how-works/index.js";

const FrontPage = (props) => {
  useEffect(() => {
    console.log("Welcome to shroom drinks!");
  }, []);

  const {
    pageContext: {
      //page: {  seo, uri },
      categories,
      categoryName,
      postSearchData: { products, options },
    },
  } = props;

  return (
    <Layout>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={"Shroom - naturalne napoje energetyczne na bazie grzybów"}
            description={
              "Naturalne, energetyczne napoje z grzybami funcjonalnymi. Zawierają superfoods takie jak: soplówka jeżowata, cordyceps czy l-teanina."
            }
            header={{ siteTitle: " Sklep" }}
          />

          {/* Hero */}
          <div
            id="hwrap"
            className="container-fluid mush"
            style={{
              padding: "0",
              minHeight: "620px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              id="hero"
              className="mask container-fluid"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                minWidth: "100%",
                height: "100%",
                minHeight: "800px",
                justifyContent: "center",
              }}
            >
              <div
                className="col-8"
                style={{
                  minHeight: "400px",
                  display: "block",
                  textAlign: "center",
                  maxWidth: "1920px",
                  margin: "auto",
                }}
              >
                <h1
                  className="text-white mob-hero spec-h"
                  style={{
                    textAlign: "center",
                    fontSize: "5em",
                    wordBreak: "break-word",
                  }}
                >
                  shroom drink
                </h1>
                <br />
                <h2
                  className="text-white"
                  style={{
                    textAlign: "center",
                    fontSize: "2em",
                    fontWeight: "800",
                    letterSpacing: "2px",
                  }}
                >
                  Twój napój wellness - stworzony z naturalnych składników o
                  potwierdzonym nauką działaniu
                </h2>
                <p
                  className="text-white"
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    maxWidth: "500px",
                    color: "#111",
                  }}
                >
                  <br />
                  <br />
                  <a
                    className="btn btn-outline-light spec-h btn-lg"
                    href="#napoje-grzybowe"
                    role="button"
                    style={{ fontVariant: "small-caps" }}
                  >
                    napij się&nbsp;
                    <span role="img" aria-label="shopping">
                      🛒
                    </span>
                  </a>
                </p>
                <br />
                <div
                  style={{
                    padding: "2em",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <a
                    id="go"
                    href="#napoje-grzybowe"
                    style={{ fontSize: "15px" }}
                  >
                    ⇩
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            id="txt-intro"
            style={{
              textAlign: "center",
              padding: "4rem 10rem",
              fontSize: "2rem",
              letterSpacing: "2px",
              backgroundColor: "#E8D3D0",
            }}
          >
            <p className="my-2">
              <span className="spec-h">shroom</span> drink został zaprojektowany
              <sup>*</sup> z myślą o Twoim holistycznym dobrostanie i
              nowoczesnym stylu życia. Dodatek adaptogenów, probiotyków, witamin
              i minerałów, dostarcza tego, czego Twoje ciało naprawdę
              potrzebuje—równowagi i skutecznego, funkcjonalnego odżywiania.
            </p>
            <div
              className="my-5"
              style={{
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              <span>
                <sup>*</sup>Mówiąc „zaprojektowany”, naprawdę to mamy na myśli.
                Użyliśmy metodologii designu, aby stworzyć napój, który jest
                przede wszystkim nastawiony na wsparcie Twojego organizmu. 
              </span>
            </div>
          </div>

          <Search
            products={products}
            initialProducts={products}
            engine={options}
            category={categoryName}
            categories={categories}
          />
        </>
      ) : (
        <div>Coś poszło nie tak...</div>
      )}
      {/*<Revs />*/}
      <SliderPhoto />
      <HowWorks />

      <div
        className="container-fuild"
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "5em 0em",
          padding: "2em 1em",
        }}
      >
        <h2>Dowiedz się więcej o naszych składnikach!</h2>
        <p style={{ maxWidth: "650px", margin: "auto" }}>
          Napoje grzybowe to prawdziwe super pożywienie, a raczej super napoje!
          Sprawdź ich niesamowite składniki aktywne, które pomogą Ci wstać na
          nogi lub zrelaksować się.
        </p>
        <br />
        <br />
        <SliderIn />
        <br />
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          <Link to="/pl/nasza-historia/" className="btn">
            dowiedz się więcej&nbsp;
            <span role="img" aria-label="shroom">
              ✨
            </span>
          </Link>
        </p>
      </div>
      <BlogModule />
      <br />
      <p style={{ textAlign: "center" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://shroom4you.com/pl/blog/"
          className="btn btn-outline-dark mush"
        >
          Odwiedź bloga
        </a>
      </p>
      <br />
      <br />
    </Layout>
  );
};
export default FrontPage;
